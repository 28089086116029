import React from "react";

import "./NotFound.scss";

function NotFound(props) {
  return (
    <div className="" style={props.style}>
        not found
    </div>
  );
}

export default NotFound;
